var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutCompactFooterOnly',[_c('div',{staticClass:"login-page"},[(_vm.image)?_c('div',{staticClass:"d-img-login"},[_c('img',{staticClass:"img-login",attrs:{"src":_vm.image,"alt":"login logo"}})]):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"mx-auto mw-270 mw-xl-945"},[_c('div',{staticClass:"notice h-80 py-2 flex-center text-center",class:_vm.message ? 'visible' : 'invisible'},[_c('div',{staticClass:"text-danger-extra text-center fs-14"},[_vm._v(" "+_vm._s(_vm.message)+" ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.handleLogin)}}},[_c('div',{staticClass:"form-group position-relative mx-auto mw-xl-670 pb-4 py-xl-0"},[_c('div',{staticClass:"d-xl-flex"},[_c('label',{staticClass:"w-xl-115 flex-shrink-0 d-flex align-items-center pr-1 fs-14"},[_c('span',{staticClass:"fwb"},[_vm._v("メールアドレス")])]),_c('div',{staticClass:"position-relative w-100"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [(_vm.isErrorEmail && errors[0])?_c('div',{staticClass:"alert-tooltip-block",on:{"click":reset}},[_c('div',{staticClass:"alert alert-tooltip text-light fs-14 mb-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],ref:"email",staticClass:"form-control form-control-xl-h-45 rounded-1 text-center fs-xl-18",attrs:{"type":"text","placeholder":"メールアドレス"},domProps:{"value":(_vm.user.email)},on:{"click":_vm.focusInEmail,"blur":_vm.focusOutEmail,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}})]}}],null,true)})],1)])]),_c('div',{staticClass:"form-group position-relative mx-auto mw-xl-670 pb-4 pb-xl-2"},[_c('div',{staticClass:"d-xl-flex"},[_c('label',{staticClass:"w-115 flex-shrink-0 d-flex align-items-center pr-1 fs-14"},[_c('span',{staticClass:"fwb"},[_vm._v("パスワード")])]),_c('div',{staticClass:"position-relative w-100"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [(_vm.isErrorPassword && errors[0])?_c('div',{staticClass:"alert-tooltip-block",on:{"click":reset}},[_c('div',{staticClass:"alert alert-tooltip text-light fs-14 mb-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],ref:"password",staticClass:"form-control form-control-xl-h-45 rounded-1 text-center fs-xl-18",attrs:{"name":"password","type":"password","placeholder":"パスワード"},domProps:{"value":(_vm.user.password)},on:{"click":_vm.focusInPassword,"blur":_vm.focusOutPassword,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}})]}}],null,true)})],1)])]),_c('button',{staticClass:"btn btn-h-40 btn-primary btn-block rounded-1 mx-auto mw-xl-270 fs-14 fs-xl-18 mt-xl-8",attrs:{"disabled":_vm.loading,"type":"submit"}},[_c('div',{staticClass:"flex-center"},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mr-3",attrs:{"variant":"light","label":"Spinning"}}),_vm._v(" ログイン ")],1)])])]}}])}),_c('div',{staticClass:"text-center mt-5 mb-10"},[_c('router-link',{staticClass:"text-primary-extra fs-14 fs-xl-18",attrs:{"to":{ name: 'forgot-password' }}},[_vm._v("パスワードをお忘れですか？")])],1)],1),_c('p',{staticClass:"text-center fs-14 fs-xl-18"},[_vm._v(" 会員登録されていない方は "),_c('router-link',{staticClass:"text-primary-extra",attrs:{"to":{ name: 'select-store' }}},[_vm._v("こちら")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }


































































































































import { Component, Vue } from 'vue-property-decorator'
import { Validator, ValidationProvider, ValidationObserver } from 'vee-validate'
import { namespace } from 'vuex-class'
import LayoutCompactFooterOnly from '@/layouts/LayoutCompactFooterOnly.vue'
import LogoService from '@/services/LogoService'

const Auth = namespace('Auth')
const ModeBuyProduct = namespace('ModeBuyProduct')

const dict = {
  custom: {
    email: {
      required: () => '「メールアドレス」を入力してください。',
      email: () => '「メールアドレス」を正しく入力してください。'
    },
    password: {
      required: () => '「パスワード」を大文字を含む半角英数字9文字以上20文字以下で入力してください。'
    }
  }
}
Validator.localize('jp', dict)

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    LayoutCompactFooterOnly
  }
})
export default class Login extends Vue {
  public loading: boolean = false
  public isErrorEmail: boolean = false
  public isErrorPassword: boolean = false
  public message: string = ''
  public image: any = ''

  private user: any = { email: '', password: '' }
  private messageError: string = `「メールアドレス」と「パスワード」を正しく入力してください。`
  @Auth.Getter
  private isLoggedIn!: boolean
  @Auth.Action
  private login!: (data: any) => Promise<any>
  @ModeBuyProduct.Action
  private SET_NEW_MODE_BUY_PRODUCT!: (data: any) => any

  created() {
    if (this.isLoggedIn) {
      this.$router.push({ name: 'home' })
    }
    LogoService.getAvatar().then((res: any) => {
      this.image = res.data.items
    })
    this.SET_NEW_MODE_BUY_PRODUCT('1')
  }

  handleLogin() {
    this.loading = true
    if (this.user.email && this.user.password) {
      this.login(this.user).then(
        (response: any) => {
          if (response.status) {
            localStorage.setItem('store_uuid', response.user.store_uuid)
            localStorage.removeItem('tour_mode')
            localStorage.removeItem('register_mode')
            this.$router.push({ name: 'home' })
          }
        },
        (error: any) => {
          if (error) {
            this.loading = false
            this.message = this.messageError
          }
        }
      )
    }
  }

  focusOutEmail() {
    this.isErrorEmail = true
  }

  focusInEmail() {
    this.isErrorEmail = false
  }

  focusOutPassword() {
    this.isErrorPassword = true
  }

  focusInPassword() {
    this.isErrorPassword = false
  }
}
